import request from "@/Rest";
import { LOCATION_URLS, ANALYTICS_URLS } from "@/Rest/api/urls";

export async function createLocationCode(location, userRole) {
  const payload = {
    location,
  };

  const role = userRole.toLowerCase();
  payload[role] = true;

  try {
    const { data } = await request.POST(
      LOCATION_URLS.createLocationCode,
      payload
    );

    return { data, success: true };
  } catch (error) {
    return { success: false };
  }
}

export async function registrationEmail({
  email,
  code = "",
  isLocationUser = false,
  isSponsorAccount = false,
  dependents = 0,
}) {
  const payload = {
    email,
    code,
    location_user: isLocationUser,
    sponsor_account: isSponsorAccount,
    dependents,
  };
  try {
    const { data } = await request.POST(
      LOCATION_URLS.registrationEmail,
      payload
    );

    return data;
  } catch (error) {
    throw new Error(error.response.data);
  }
}

export async function locations() {
  try {
    const { data } = await request.GET(LOCATION_URLS.getLocations);

    return { data, success: true };
  } catch (error) {
    return { success: false };
  }
}

export async function validateFacilityCode(facilityCode) {
  try {
    const payload = { facility_code: facilityCode };
    const { data } = await request.POST(LOCATION_URLS.validateFacilityCode, payload);
    return { data, success: true };
  } catch (error) {
    return { success: false };
  }
}

export async function locationPrices(facilityCode) {
  try {
    const params = { facility_code: facilityCode };
    const { data } = await request.GET(LOCATION_URLS.getLocationPrices, { params });

    return { data, success: true };
  } catch (error) {
    return { success: false };
  }
}

export async function getSponsorAccounts(page = 1) {
  try {
    const { data } = await request.GET(LOCATION_URLS.getSponsorAccounts(page));

    return { data, success: true };
  } catch (error) {
    return { success: false };
  }
}

export async function numberSeats(params) {
  try {
    const { data } = await request.GET(LOCATION_URLS.getNumberSeats, {
      params,
    });

    return { data, success: true };
  } catch (error) {
    return { success: false };
  }
}

export async function subtractSeat(location, quantity) {
  const payload = { location, quantity };
  try {
    const { data } = await request.POST(LOCATION_URLS.subtractSeat, payload);

    return { data, success: true };
  } catch (error) {
    return { success: false };
  }
}

export async function plustSeats(location, quantity) {
  const payload = { location, quantity };
  try {
    const { data } = await request.POST(LOCATION_URLS.plustSeats, payload);

    return { data, success: true };
  } catch (error) {
    return { success: false };
  }
}

export async function createLocation(name, facility_code) {
  const payload = { name, facility_code };
  try {
    const { data } = await request.POST(LOCATION_URLS.baseUrl, payload);

    return { data, success: true };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
}

export async function getUsers(page = 1) {
  try {
    const { data } = await request.GET(LOCATION_URLS.getUsers(page));

    return { data, success: true };
  } catch (error) {
    return { success: false };
  }
}

export async function getTotalUsers(location) {
  try {
    const { data } = await request.GET(LOCATION_URLS.getTotalUsers(location));

    return { data, success: true };
  } catch (error) {
    return { success: false };
  }
}

export async function getTotalSeats(location) {
  try {
    const { data } = await request.GET(LOCATION_URLS.getTotalSeats(location));

    return { data, success: true };
  } catch (error) {
    return { success: false };
  }
}

export async function getTotalDelegates(location) {
  try {
    const { data } = await request.GET(
      LOCATION_URLS.getTotalDelegates(location)
    );

    return { data, success: true };
  } catch (error) {
    return { success: false };
  }
}

export async function getTotalLocationAdmins() {
  try {
    const { data } = await request.GET(LOCATION_URLS.getTotalLocationAdmins);

    return { data, success: true };
  } catch (error) {
    return { success: false };
  }
}

export async function getInvitations(page = 1) {
  try {
    const { data } = await request.GET(LOCATION_URLS.getInvitations(page));

    return { data, success: true };
  } catch (error) {
    return { success: false };
  }
}

export async function deleteInvitation(invitation) {
  try {
    const payload = {
      invitation,
    };
    const { data } = await request.DELETE(LOCATION_URLS.invitations, payload);

    return { data, success: true };
  } catch (error) {
    return { success: false };
  }
}

export async function getFreeTrial() {
  try {
    const { data } = await request.GET(LOCATION_URLS.getFreeTrial);

    return data;
  } catch (error) {
    throw new Error(error);
  }
}

export async function getTotalTrainigSessions({ location, days=7 }) {
  try {
    const { data } = await request.GET(
      LOCATION_URLS.getTotalTrainigSessions(location, days)
    );

    return data;
  } catch (error) {
    throw new Error(error);
  }
}

export async function getTotalUsersPerSessions({ location, days=7 }) {
  try {
    const { data } = await request.GET(
      LOCATION_URLS.getTotalUsersPerSessions(location, days)
    );

    return data;
  } catch (error) {
    throw new Error(error);
  }
}

export async function getAnalyticsUserData(location, orderQuery, page = 1) {
  try {
    const orderQueryStr = orderQuery.join(',');
    const params = { location, order_by: orderQueryStr };
    const { data } = await request.GET(ANALYTICS_URLS.getUserData(page), { params });

    return data;
  } catch (error) {
    throw new Error(error);
  }
}

export async function getAnalyticsUserDataReport(recordIds, orderQuery) {
  try {
    const payload = { records_ids: recordIds, order_by: orderQuery };

    const { data } = await request.POST(ANALYTICS_URLS.getUserDataReport, payload, { responseType: 'blob' });

    return data;
  } catch (error) {
    throw new Error(error);
  }
}
