import request from "@/Rest";
import { STRIPE_URLS } from "@/Rest/api/urls";

export async function createClient(email, name) {
  const body = {
    email,
    name,
  };
  try {
    const { data } = await request.POST(STRIPE_URLS.createClient, body);

    return { data, success: true };
  } catch (error) {
    return { success: false };
  }
}

export async function createSubscription({
  customerId,
  paymentMethodId,
  priceId,
  quantity,
  proration,
  facilityCode,
  couponId = "",
  discountPercentage = 0.0,
}) {
  const body = {
    customerId,
    paymentMethodId,
    priceId,
    quantity,
    couponId,
    proration,
    facilityCode,
    discountPercentage
  };
  try {
    const { data } = await request.POST(STRIPE_URLS.createSubscription, body);

    return { data, success: true };
  } catch (error) {
    return { success: false };
  }
}

export async function createB2CUser({ customerId, quantity, coupon, facilityCode }) {
  const body = {
    customerId,
    quantity,
    coupon,
    facilityCode
  };
  try {
    await request.POST(STRIPE_URLS.createB2CUser, body);
  } catch (error) {
    throw new Error("Something went wrong please try again");
  }
}

export async function buySeats(
  paymentMethodId,
  priceId,
  quantity,
  couponId = ""
) {
  const body = {
    ...(paymentMethodId !== null ? { paymentMethodId: paymentMethodId } : {}),
    priceId,
    quantity,
    couponId,
  };
  try {
    const { data } = await request.POST(STRIPE_URLS.createSeats, body);

    return { data, success: true };
  } catch (error) {
    return { success: false };
  }
}

export async function createPay(
  customerId,
  paymentMethodId,
  quantity,
  amount,
  priceId,
  couponId = ""
) {
  const body = {
    customerId,
    paymentMethodId,
    quantity,
    amount,
    couponId,
    priceId,
  };
  try {
    const { data } = await request.POST(STRIPE_URLS.createPay, body);

    return { data, success: true };
  } catch (error) {
    return { success: false };
  }
}

export async function getPromotionCodes() {
  try {
    const { data } = await request.GET(STRIPE_URLS.getPromotionCodes);

    return { data, success: true };
  } catch (error) {
    return { success: false };
  }
}

export async function getProductInfo(code, location) {
  try {
    const { data } = await request.GET(
      STRIPE_URLS.getProduct(code ?? "", location ?? "")
    );

    return { data, success: true };
  } catch (error) {
    return { success: false };
  }
}

export async function getStripeDiscountCode(discountCode) {
  try {
    const params = { discount_code: discountCode };
    const { data } = await request.GET(STRIPE_URLS.getStripeDiscountCode, { params });

    return data;
  } catch (error) {
    throw new Error(error);
  }
}
