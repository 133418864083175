<template>
  <div class="m-4">
    <h1>Buy Seats</h1>
    <form @submit.prevent="handleSubmit" class="login-form w-50">
      <div class="form-group w-50">
        <label for="nSeats" class="d-block">Number of seats</label>
        <input
          class="form-input grey-text"
          type="number"
          id="nSeats"
          v-model="nSeats"
          required
        />
      </div>
      <h3 class="mt-3">Payment Details</h3>
      <div class="form-group">
        <label for="name">Name on card</label>
        <input
          id="name"
          class="form-control"
          type="text"
          aria-describedby="emailHelp"
          v-model="this.nameOnCard"
          placeholder="John Doe"
        />
      </div>
      <div class="my-3 border p-2" ref="card"></div>
      <p class="text-danger">{{ this.stripeValidationError }}</p>
      <p class="text-danger" v-if="this.showCardError">
        There is a problem with your card, please try again!
      </p>
      <button class="btn btn-primary w-100" type="submit">
        Pay <span>${{ this.price }}</span>
      </button>
    </form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import { createPay, createClient } from "@/services/stripe/stripe.js";
import { plustSeats } from "@/services/location/location.js";

// eslint-disable-next-line no-undef
const stripe = Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
let card = undefined;

export default {
  name: "SponsorAccount",
  data() {
    return {
      email: "",
      nSeats: "",
      stripeValidationError: "",
      showCardError: false,
      price: this.nSeats,
      nameOnCard: "",
    };
  },
  methods: {
    async handleSubmit() {
      try {
        const customerId = await this.createClientRequest();

        const result = await stripe.createPaymentMethod({
          type: "card",
          card: card,
          billing_details: {
            name: this.nameOnCard,
          },
        });

        const response = await createPay(
          customerId,
          result.paymentMethod.id,
          this.nSeats,
          this.price,
          this.promotionCodeId
        );

        if (!response.success) {
          this.showCardError = true;
          return;
        }

        await plustSeats(this.getUserData.location, this.nSeats);

        this.toast.success("Successful purchase!");
        this.nSeats = "";
        this.nameOnCard = "";
        card.clear();
      } catch (e) {
        this.showCardError = true;
      }
    },
    setValidationError(event) {
      this.stripeValidationError = event.error ? event.error.message : "";
    },
    async createClientRequest() {
      const response = await createClient(
        this.getUserData.email,
        this.getUserData.name
      );
      return response.data.customer.id;
    },
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  beforeUpdate() {
    this.price = parseInt(this.nSeats) * 10;
  },
  computed: {
    ...mapGetters("user", ["getUserData"]),
  },
  mounted() {
    const elements = stripe.elements();
    const style = {
      base: {
        iconColor: "black",
        color: "black",
        fontWeight: 500,
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",

        ":-webkit-autofill": {
          color: "#fce883",
        },
      },
      invalid: {
        iconColor: "red",
        color: "red",
      },
    };

    card = elements.create("card", { style });
    card.mount(this.$refs.card);
    card.on("change", this.setValidationError);
  },
};
</script>
