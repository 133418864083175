<template>
  <SponsorAccounts />
</template>

<script>
import SponsorAccounts from "@/components/users/buySponsorAccounts.vue";

export default {
  name: "BuySponsorsAccounts",
  components: {
    SponsorAccounts,
  },
};
</script>
